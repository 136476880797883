<template>
  <div>

    <b-card-actions
      v-for="node in orderedNodes"
      :key="node.id"
      :title="`Node ${node.id}`"
      :action-collapse="true"
    >
      <b-row v-if="!isUtility(node)">
        <b-col>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th>Id</th>
                <td>
                  {{ node.id }}
                </td>
              </tr>
              <tr>
                <th>IP</th>
                <td>
                  {{ node.ip }}
                </td>
              </tr>
              <tr v-if="node.agentHardwareData && node.agentHardwareData.chassisInfo && node.agentHardwareData.chassisInfo.chassisInformations.length > 0">
                <th>Hostname</th>
                <td>
                  {{ node.agentHardwareData.chassisInfo.chassisInformations[0].hostname }}
                </td>
              </tr>
              <tr v-if="node.agentHardwareData && node.agentHardwareData.chassisInfo && node.agentHardwareData.chassisInfo.chassisInformations.length > 0">
                <th>BIOS Version</th>
                <td>
                  {{ node.agentHardwareData.chassisInfo.chassisInformations[0].biosVersion }}
                </td>
              </tr>
              <tr v-if="node.agentHardwareData && node.agentHardwareData.chassisInfo && node.agentHardwareData.chassisInfo.chassisInformations.length > 0">
                <th>Chassis Serialnumber</th>
                <td>
                  {{ node.agentHardwareData.chassisInfo.chassisInformations[0].serialNumber }}
                </td>
              </tr>
              <tr>
                <th>MAC Addr.</th>
                <td>
                  {{ node.macAddress }}
                </td>
              </tr>
              <tr>
                <th>State</th>
                <td>
                  {{ node.state }}
                </td>
              </tr>
              <tr>
                <th>HFS Create Time</th>
                <td>
                  {{ node.hfsCreateTime | formatTimeSpanFromNow }}
                </td>
              </tr>
              <tr>
                <th>Uptime</th>
                <td>
                  {{ node.uptime }}
                </td>
              </tr>
              <tr v-if="node.license">
                <th>License Status</th>
                <td>
                  {{ node.license.status }}
                </td>
              </tr>
              <tr v-if="node.license">
                <th>License Expiration</th>
                <td>
                  {{ node.license.expiration }}
                </td>
              </tr>
              <tr v-if="node.connectedStatus">
                <th>Is connected</th>
                <td>
                  {{ node.connectedStatus.isConnected }}
                </td>
              </tr>
              <tr v-if="node.connectedStatus">
                <th>Is stable</th>
                <td>
                  {{ node.connectedStatus.isStable }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
        <b-col>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr v-if="node.version">
                <th>Gsan Version</th>
                <td>
                  {{ node.version.gsan }}
                </td>
              </tr>
              <tr v-if="node.version">
                <th>Kernel</th>
                <td>
                  {{ node.version.kernel }}
                </td>
              </tr>
              <tr v-if="node.gcStatus">
                <th>GC Result</th>
                <td>
                  {{ node.gcStatus.result }}
                </td>
              </tr>
              <tr v-if="node.cpStatus">
                <th>CP Start time</th>
                <td>
                  {{ node.cpStatus.startTime | formatDateTimeTz(asup.timezone) }}
                </td>
              </tr>
              <tr v-if="node.cpStatus">
                <th>CP Status</th>
                <td>
                  {{ node.cpStatus.status }}
                </td>
              </tr>
              <tr v-if="node.sysConfig && node.sysConfig.hwCheck">
                <th>Gen. Descr.</th>
                <td>
                  {{ node.sysConfig.hwCheck.genDesc }}
                </td>
              </tr>
              <tr v-if="node.sysConfig && node.sysConfig.hwCheck && node.sysConfig.hwCheck.sysReq">
                <th>CPU Model</th>
                <td>
                  {{ node.sysConfig.hwCheck.sysReq.cpuModel }}
                </td>
              </tr>
              <tr v-if="node.sysConfig && node.sysConfig.hwCheck && node.sysConfig.hwCheck.sysReq">
                <th>CPU Speed</th>
                <td>
                  {{ node.sysConfig.hwCheck.sysReq.minCpuSpeed }}
                </td>
              </tr>
              <tr v-if="node.sysConfig && node.sysConfig.hwCheck && node.sysConfig.hwCheck.sysReq">
                <th>Memory Size (MB)</th>
                <td>
                  {{ node.sysConfig.hwCheck.sysReq.minMemsizeMb }}
                </td>
              </tr>
              <tr v-if="node.sysConfig && node.sysConfig.hwCheck && node.sysConfig.hwCheck">
                <th>Is VM</th>
                <td>
                  {{ node.sysConfig.hwCheck.isVm }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th>Id</th>
                <td>
                  {{ node.id }}
                </td>
              </tr>
              <tr>
                <th>IP</th>
                <td>
                  {{ node.ip }}
                </td>
              </tr>
              <tr v-if="node.agentHardwareData && node.agentHardwareData.chassisInfo && node.agentHardwareData.chassisInfo.chassisInformations.length > 0">
                <th>Chassis Serialnumber</th>
                <td>
                  {{ node.agentHardwareData.chassisInfo.chassisInformations[0].serialNumber }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
        <b-col />
      </b-row>
    </b-card-actions>
  </div>
</template>

<script>

import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    orderedNodes() {
      return this.asup.details.nodes.concat().sort((a, b) => (a.id > b.id ? 1 : -1))
    },
  },
  methods: {
    isUtility(node) {
      return node && node.id === 'Utility'
    },
  },
}
</script>

<style scoped>
  .config-table-wrapper {
    max-height: 400px;
    overflow-y: auto
  }
</style>
